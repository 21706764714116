document.addEventListener("turbolinks:load", function()  {

    // Recherche de contact
    $('#partners_search input').keyup(function () {
        $.get($('#partners_search').attr('action'), $('#partners_search').serialize(), null, 'script');
        return false;
    });


    $('.iban').mask('SS00 0000 0000 0000 0000 0000 000', {
        placeholder: '____ ____ ____ ____ ____ ____ ___'
    });

    // Partner comment modal
    //
    $('#partner-save-comment').click(function (e) {
        e.preventDefault();

        const title = $('#comment_title').val();
        const body = $('#comment_body').val();
        const status = $('#comment_status').val();

        const day = $('#comment_due_date_3i').find(":selected").text();
        let month = $('#comment_due_date_2i').find(":selected").text();
        const year = $('#comment_due_date_1i').find(":selected").text();


        switch (month) {
            case "janvier":
                month = '01';
                break;
            case "février":
                month = '02';
                break;
            case "mars":
                month = '03';
                break;
            case "avril":
                month = '04';
                break;
            case "mai":
                month = '05';
                break;
            case "juin":
                month = '06';
                break;
            case "juillet":
                month = '07';
                break;
            case "aout":
                month = '08';
                break;
            case "septembre":
                month = '09';
                break;
            case "octobre":
                month = '10';
                break;
            case "novembre":
                month = '11';
                break;
            case "décembre":
                month = '12';
                break;
        }

        const due_date = year + '-' + month + '-' + day;

        const user = $('#partner_data').data('user');
        const partner = $('#partner_data').data('partner');

        if (!title) {
            alert('Le titre doit être renseigné');
            return false;
        }

        if (!body) {
            alert('Le corp du commentaire doit être renseigné');
            return false;
        }

        $(".comment-title:last").val(title);
        $(".comment-text-title:last").text(title);
        $(".comment-body:last").val(body);
        $(".comment-text-body:last").text(body);
        $(".comment-status:last").val(status);
        $(".comment-due-date:last").val(due_date);
        $(".comment-text-due-date:last").text(due_date);
        $(".comment-user:last").val(user);

// New comment modal close (with clear fields)
        $('#comment_title').val('');
        $('#comment_body').val('');
        $('#comment_due_date').val('');
        return $('#addCommentModal').modal('hide');
    });



// Picture preview
// ---------------------------------------------------------------------------
    updatePreview();
    return $('#partner-brands').on('cocoon:after-insert', () => updatePreview());
});

// END Picture preview
// ---------------------------------------------------------------------------


var updatePreview =  () => {

    $(".logo_file").change(function () {
        const thumb = $(this).closest('tr').find('.thumb');
        const reader = new FileReader();

        reader.onload = function (e) {
            // get loaded data and render thumbnail.
            const img = $('<img>');
            img.attr('src', e.target.result);
            thumb.html("");
            img.appendTo(thumb);

        };

// read the image file as a data URL.
        reader.readAsDataURL(this.files[0]);
    });
};
