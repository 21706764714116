


import './contact.js'
import './customer.js'
import './partner.js'
import './product.js'
import './top_chronos.js'

let set_view = function() {
    // Set the views
    //
    if ($('#view').text() === 'thumbnail') {
        $('#item-thumbnail').show();
        $('#item-spreadsheet').hide();
    } else {
        $('#item-thumbnail').hide();
        $('#item-spreadsheet').show();
    }
};


document.addEventListener("turbolinks:load", function()  {


    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop").on('click', function(e) {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $('.sidebar .collapse').collapse('hide');
        };
    });

    // Fermeture automatique des alerts Bootstrap
    window.setTimeout(function() {
        $(".alert").fadeTo(1000, 0).slideUp(800, function(){
            $(this).remove();
        });
    }, 4000);


    // Gestion de l'affichage Thumbnail / Spreadheet
    //
    set_view();

    $("i[data-view]").click(function(e) {

        e.preventDefault();
        let view = $(this).data("view");
        $('#view').text(view);

        set_view()
    });


    // Gestion du menu GAUCHE
    //
    $('div.bhoechie-tab-menu>div.list-group>a').click(function (e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass('active');
        $(this).addClass('active');
        const index = $(this).index();
        $('div.bhoechie-tab>div.bhoechie-tab-content').removeClass('active');
        $('div.bhoechie-tab>div.bhoechie-tab-content').eq(index).addClass('active');
    });



});
