


document.addEventListener("turbolinks:load", function()  {

    // Recherche de clients
    $('#customers_search input').keyup(function () {
        $.get($('#customers_search').attr('action'), $('#customers_search').serialize(), null, 'script');
        return false;
    });

});
