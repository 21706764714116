
var DapimTopChrono = {

    updatePreview: function () {

        $('.picture_file').change(function () {

            const reader = new FileReader();

            reader.onload = function (e) {
                $('#top_chronos-image').attr("src", e.target.result);
            };

            reader.readAsDataURL(this.files[0]);

        });
    },
};


document.addEventListener("turbolinks:load", function()  {

    if (($('.top_chronos.edit').length === 0) &&
        ($('.top_chronos.new').length === 0) &&
        ($('.top_chronos.create').length === 0)) {
        return;
    }

    DapimTopChrono.updatePreview();

    // TopChronos tag autocompletion
    let availableTags = [];

    $.get('/tags.json', function (data) {
        availableTags = data;
        return $('#top_chrono_tag_list').tagit({
            availableTags,
            allowSpaces: true
        });
    });
});
