

var DapimProduct = {

    updatePreview: function () {

        $('.picture_file').change(function () {

            var reader, thumb;
            thumb = $(this).closest('tr').find('.thumb');
            reader = new FileReader();
            reader.onload = function (e) {
                var img;
                img = $('<img>');
                img.attr('src', e.target.result);
                thumb.html("");
                img.appendTo(thumb);
            };

            reader.readAsDataURL(this.files[0]);

        });
    },


    check_to_hide_or_show_product_add_link: function () {

        if ($('#product-pictures .nested-fields').length > 4) {
            $('#add-picture a').hide();
        } else {
            $('#add-picture a').show();
        }

    },


    aferCocoonInsert: function () {

        $('#product-pictures').bind('cocoon:after-insert', function () {
            DapimProduct.updatePreview();
            DapimProduct.check_to_hide_or_show_product_add_link();
        });

    },


    aferCocoonRemove: function () {

        $('#product-pictures').bind('cocoon:after-remove', function () {

            DapimProduct.check_to_hide_or_show_product_add_link();
        });

    }
};


document.addEventListener("turbolinks:load", function()  {

    DapimProduct.updatePreview();

    DapimProduct.aferCocoonInsert();
    DapimProduct.aferCocoonRemove();


    // Recherche de produit
    $('#products_search input').keyup(function () {
        $.get($('#products_search').attr('action'), $('#products_search').serialize(), null, 'script');
        return false;
    });

    // Filtre sur les produits inactif
    $('#product_filter_inactive').change(function () {
        $.get($('#products_search').attr('action'), $('#products_search').serialize(), null, 'script');
        return false;
    });

    // Filtre sur les produits invalides
    //
    $('#product_filter_invalid').change(function () {
        $.get($('#products_search').attr('action'), $('#products_search').serialize(), null, 'script');
        return false;
    });


    // Gestion des tags
    //
    availableTags = [];
    $.get('/tags.json', function (data) {
        availableTags = data;
        return $('#product_tag_list').tagit({
            availableTags: availableTags,
            allowSpaces: true
        });
    });
    $('#product_tag_list').hide();

    $('#categories_tree').on('changed.jstree', function (e, data) {

        var i, id, j, r, str;
        r = [];
        i = 0;
        j = data.selected.length;

        while (i < j) {
            str = data.instance.get_node(data.selected[i]).text;

            // Recuperation de l'ID de la categorie selectionne
            /.*>(.*)<\/span>/.exec(str);
            id = RegExp.$1;
            r.push(id);
            i++;
        }

        $('#product_category_ids option').prop('selected', false);

        $.each(r, function (i, e) {
            $('#product_category_ids option[value=\'' + e + '\']').prop('selected', true);
        });
    }).jstree({
        'checkbox': {
            'three_state': false
        },
        'plugins': ["wholerow", "checkbox"]
    });

    categories = $("#product_category_ids").val();

    $('#categories_tree').jstree('open_all');

    $.each(categories, function (i, e) {
        id = $('#cat-' + e).closest("a").attr("id");
        return $('#categories_tree').jstree("select_node", id);
    });

    $('#categories_tree').jstree('close_all');

    $('.select-value').on('change', function () {
        $('#product_value_ids option').removeAttr("selected");
        return $.each($('.select-value'), function (i, e) {
            return $('#product_value_ids option[value=\'' + e.value + '\']').prop('selected', true);
        });
    });


});

// (function () {
//   var countDown, pagination_product, updatePreview;
//
//   $( document ).ready(function() {
//     var availableTags, categories, check_to_hide_or_show_product_add_link, features;
//
//     // Le fichier n'est pas chargé si la page n'est pas une page Produits
//     //
//     if ($('.products.edit').length === 0 &&
//         $('.products.new').length === 0 &&
//         $('.products.index').length === 0 &&
//         $('.products.create').length === 0) {
//       return;
//     }
//

//     features = $("#product_value_ids").val() || [];
//     $.each(features, function (i, value) {
//       return $.each($('.select-value'), function (ii, select) {
//         return $('#' + select.id + ' option[value=\'' + value + '\']').prop('selected', true);
//       });
//     });
//
//     countDown($("#product_name"), $("#product_name_counter"));
//     countDown($("#product_meta_title"), $("#product_meta_title_counter"));
//     countDown($("#product_meta_description"), $("#product_meta_description_counter"));
//     countDown($("#product_url_rewritten"), $("#product_url_rewritten_counter"));
//     countDown($("#product_visitor_price_message"), $("#product_visitor_price_message_counter"));
//     countDown($("#product_member_price_message"), $("#product_member_price_message_counter"));
//     updatePreview();
//     return $('#product-pictures').on('cocoon:after-insert', function () {
//       return updatePreview();
//     });
//   });
//
//
//   countDown = ($source, $target) => {
//     var max;
//     if ($source.val() == null) {
//       return;
//     }
//     max = $source.attr('data-maxchar');
//     $target.html(max - ($source.val().length));
//     $source.keyup(function () {
//       $target.html(max - ($source.val().length));
//     });
//   };
//